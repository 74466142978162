// @import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap');
@import "~bootstrap/scss/functions";

@import "variables";

@import "~bootstrap/scss/bootstrap";

@import "bootstrap/scss/functions";

// @import "variables";
@import "bootstrap/scss/variables";

@import "bootstrap/scss/maps";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/root";

@import "bootstrap/scss/utilities";
@import "bootstrap/scss/reboot";
@import "bootstrap/scss/type";
@import "bootstrap/scss/images";
@import "bootstrap/scss/containers";
@import "bootstrap/scss/grid";
@import "bootstrap/scss/helpers";

@import "bootstrap/scss/utilities/api";

@import "bootstrap/scss/transitions";
@import "~bootstrap/scss/accordion";
@import "bootstrap/scss/nav";
@import "bootstrap/scss/list-group";
@import "bootstrap/scss/close";
@import "bootstrap/scss/placeholders";
@import "bootstrap/scss/dropdown";

* {
  outline: none !important;
}

html,
body {
  max-width: 100% !important;
  overflow-x: hidden !important;
}

.footer-contact {
  width: 1.6rem !important;
}

.bg-light-1 {
  background-color: #D0D0C8;
}

.bg-light-2 {
  background-color: #cfd8dab0 !important;
}

.headerIconWidth {
  width: 32px !important;
}

.aboutFontHeader {
  font-size: 2.4rem !important;
}

.testMoniFont {
  font-size: 2.4rem !important;
}

.bgCity {
  background: linear-gradient(180deg, $primary 53%, white 0%);
}

.fontBold {
  font-weight: 600 !important;
}

.hNavbar{
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.hLogo{
  height: 3rem !important;
}

.insightBanner {
  background-image: url("../images/insightBanner.png");
  background-size: cover;
  background-repeat: no-repeat;
  height: 9rem !important;
  font-weight: 600 !important;
}
.aboutUsBanner {
  background-image: url("../images/aboutUsBanner.png");
  background-size: cover;
  background-repeat: no-repeat;
  height: 9rem !important;
  font-weight: 600 !important;
}

.careerBanner {
  background-image: url("../images/careerBanner.png");
  background-size: cover;
  background-repeat: no-repeat;
  height: 9rem !important;
  font-weight: 600 !important;
}

.clienteleBanner {
  background-image: url("../images/clienteleBanner.png");
  background-size: cover;
  background-repeat: no-repeat;
  height: 9rem !important;
  font-weight: 600 !important;
}

.servicesBanner {
  background-image: url("../images/servicesBanner.png");
  background-size: cover;
  background-repeat: no-repeat;
  height: 9rem !important;
  font-weight: 600 !important;
}

.backpic {
  background-image: url("../images/backimg.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.testimonialBg {
  background-image: url("../images/testimonial.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.bg-white-card {
  background: rgb(240, 240, 240);
  background: linear-gradient(180deg, rgba(240, 240, 240, 1) 92%, rgba(0, 212, 255, 1) 0%);
}

.bg-white-card-blue {
  background: rgb(240, 240, 240);
  background: linear-gradient(180deg, rgba(240, 240, 240, 1) 92%, rgba(0, 212, 255, 1) 0%);
}

.bg-white-card-pink {
  background: rgb(240, 240, 240);
  background: linear-gradient(180deg, rgba(240, 240, 240, 1) 92%, rgb(245, 76, 64) 0%);
}

.bg-white-card-2 {
  background: rgb(240, 240, 240);
  background: linear-gradient(180deg, rgba(240, 240, 240, 1) 95%, rgba(0, 212, 255, 1) 0%);
}

.bgPink {
  background-color: rgb(245, 76, 64) !important;
}

.bgBlue {
  background-color: rgb(0, 212, 255) !important;
}

.bgBlueService {
  background-color: #0081bb !important;
}

.h20 {
  height: 20px !important;
}

.rounded-1 {
  border-radius: 20px !important;
}

.border1 {
  border: 1px solid gray !important;
}

.borderRight {
  border-right: 2px solid #d6d6d6 !important;
}

.borderRadiusCard1 {
  border-radius: 48px 0px 0px 0px !important;
}

.borderRadiusCard2 {
  border-radius: 48px 48px 0px 0px !important;
}

.borderRadiusCard3 {
  border-radius: 0px 48px 0px 0px !important;
}

.vetranImg {
  width: 8rem;
  border: 4px solid rgba(0, 212, 255, 1);
  border-radius: 50%;
}

.vetranImg-blue {
  width: 8rem;
  border: 4px solid rgba(0, 212, 255, 1);
  border-radius: 50%;
}

.vetranImg-pink {
  width: 8rem;
  border: 4px solid rgb(245, 76, 64);
  border-radius: 50%;
}

.vetPadding {
  padding-left: 22px !important;
}

.dotVet {
  font-size: 12px !important;
  color: rgb(197, 44, 44) !important;
}

.dotCheck {
  font-size: 16px !important;
}

.dilipPortrait {
  height: 138px !important;
  width: 140px !important;
}

.active {
  :first-child.navItem {
    // background-color: #111A2D !important;
    color: #fff !important;
    // border-radius: 10px;
  }
}

.insightpic {
  background-image: url("../images/insight.png");
  background-size: cover;
  height: 25rem;
  background-repeat: no-repeat;
}

.newsletter {
  background-image: url("../images/newsletter.png");
  background-size: cover;
  height: 38rem;
  background-repeat: no-repeat;
}

.associate {
  background-image: url("../images/accosiate.png");
  background-size: cover;
  // height: 38rem;
  background-repeat: no-repeat;
}

.testimonial {
  background-image: url("../images/testimonial.png");
  background-size: cover;
  height: 34rem;
  background-repeat: no-repeat;
}

.footerBg {
  background-image: url("../images/footerBg.png");
  background-size: cover;
  background-repeat: no-repeat;
}


.footerSocialIcon {
  width: 42px !important;
}

.myclr {
  // background: linear-gradient(0deg, rgba(103,12,12,1) 0%, rgba(233,0,0,1) 100%);
  background: linear-gradient(0deg, #111A2D 0%, #3756a4 100%);
}

.mainslider {
  background-image: none;
}

.about-active-color {
  background-color: #E8E8E8;

}

ul {
  list-style-image: url('../images/arrow-right-red.png');
}

ul {
  list-style-type: none;
}


.fs2 {
  font-size: 32px !important;
}

.fs3 {
  font-size: 26px !important;
}

.fs4 {
  font-size: 22px !important;
}

.fs5 {
  font-size: 18px !important;
}

.fs6 {
  font-size: 16px !important;
}

.fs70 {
  font-size: 70px !important;
}

.group14logo {
  width: 3.4rem;
}

.textJustify{
  text-align: justify;
}

.contact-info {
  font-size: 14px;
  margin-bottom: 4px;
}

.redClr {
  color: #ffffff !important;
  -webkit-text-fill-color: #c40a0a !important;
  -webkit-text-stroke-width: 0.1vw;
}

.bluClr {
  color: #ffffff !important;
  -webkit-text-fill-color: #2f519a !important;
  -webkit-text-stroke-width: 0.1vw;
}

.bannerTxt {
  font-size: 3.6vw !important;
  font-weight: bolder !important;
}

.dot-border {
  border-left: 3px dotted #D0D0C8;
  border-right: 3px dotted #D0D0C8;
  padding: 0rem 1rem;
}

.card-1 {
  background: #dcdcdc !important;
}

.card-2 {
  background: #f5e7d3 !important;
}

.evenOdd {
  background: #f5e7d3 !important;
}

.oddEven {
  background: #dcdcdc !important;
}

.dShow {
  display: none;
}

.from-label {
  margin-bottom: unset !important;
  font-size: 14px;
}

// .customInd{  
//   width: 12px !important;
//   height: 12px !important;
//   border-radius: 50% !important;
// }

// .customInd.active{
//   border-radius: 13px !important;
//   width: 30px !important;
// }

.testCard{
  height: 340px !important;
}

.px5{
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}
.logoC{
  text-align: center;
}

.dTab {
  display: none;
}

.picObjectFit{
  object-fit: contain;
}

// Tablet View
@media only screen and (min-device-width : 601px) and (max-device-width: 1024px){
  .dBlock {
    display: none;
  }

  .dTab {
    display: block;
  }

  .vh100{
    // height: 70vh !important;
    // object-fit: cover;
    // object-position: left;
  }

}
 
// Mob View
@media only screen and (max-width: 600px) {

  // .myscreen{ 
  //   background-color: green;
  //   padding-left: 5px;
  //   padding-right: 5px;
  // }

  .logoC{
    text-align: right;
  }

  .px5{
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .testCard{
    height: auto !important;
  }
  .mobCenter{
    text-align: center !important;
    margin-top: 1rem !important;
  }

  .hNavbar{
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .hLogo{
    height: 1.8rem !important;
  }
  .aboutFontHeader, .testMoniFont{
    font-size: 1.8rem !important;
  }

  .borderRight{
    border-right: unset !important;
  }

  .evenOdd {
    background: #dcdcdc !important;
  }

  .oddEven {
    background: #f5e7d3 !important;
  }

  .fs2 {
    font-size: 16px !important;
  }

  .fs3 {
    font-size: 20px !important;
  }

  .fs4 {
    font-size: 18px !important;
  }

  .fs70 {
    font-size: 24px !important;
  }

  .group14logo {
    width: 1.7rem;
  }

  .abso-banner {
    margin-top: 0.5rem !important;
    margin-left: 1.5rem !important;
  }

  .dot-border {
    border-top: 3px dotted #D0D0C8;
    border-bottom: 3px dotted #D0D0C8;
    border-right: none;
    padding: 1rem 0rem;
  }


  .dBlock {
    display: none;
  }

  .dShow {
    display: block;
  }

  .vh100{
    // height: 70vh !important;
    // object-fit: cover;
    // object-position: left;
  }

}


//   .MuiAlert-icon {
//     margin-right: 12px;
//     padding: 7px 0;
//     display: -webkit-box;
//     display: -webkit-flex;
//     display: -ms-flexbox;
//     display: flex;
//     font-size: 40px !important;
//     opacity: 0.9;
// }


.navbar-nav .nav-link {
  color: $primary;
}

.navbar-nav .nav-link.active {
  color: rgb(197, 44, 44);
}

.navbar {
  padding: 0;
}


.mybd {
  margin: 0;
  display: grid;
  grid-template-rows: 275px 50px;
  grid-template-columns: 1fr 30px 30px 30px 30px 30px 1fr;
  align-items: center;
  justify-items: center;
}

main#carousel {
  grid-row: 1 / 2;
  grid-column: 1 / 8;
  width: 100%;
  height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  transform-style: preserve-3d;
  perspective: 600px;
  --items: 5;
  --middle: 3;
  --position: 1;
  pointer-events: none;
}

div.item {
  position: absolute;
  width: 300px;
  height: 400px;
  background-color: coral;
  --r: calc(var(--position) - var(--offset));
  --abs: max(calc(var(--r) * -1), var(--r));
  transition: all 0.25s linear;
  transform: rotateY(calc(-0deg * var(--r))) translateX(calc(-350px * var(--r)));
  z-index: calc((var(--position) - var(--abs)));
  display: flex;
  justify-content: center;
  align-items: center;
  color: white !important;
}

div.item:nth-of-type(1) {
  --offset: 1;
  background-color: #194e9d;

}

div.item:nth-of-type(2) {
  --offset: 2;
  background-color: #194e9d;
}

div.item:nth-of-type(3) {
  --offset: 3;
  background-color: #194e9d;
}

div.item:nth-of-type(4) {
  --offset: 4;
  background-color: #194e9d;
}

div.item:nth-of-type(5) {
  --offset: 5;
  background-color: #194e9d;
}

div.item:nth-of-type(6) {
  --offset: 6;
  background-color: #194e9d;
}

div.item:nth-of-type(7) {
  --offset: 7;
  background-color: #194e9d;
}

div.item:nth-of-type(8) {
  --offset: 8;
  background-color: #194e9d;
}

// div.item:nth-of-type(9) {
//   --offset: 9;
//   background-color: #e9ff70;
// }

input:nth-of-type(1) {
  grid-column: 2 / 3;
  grid-row: 2 / 3;
}

input:nth-of-type(1):checked~main#carousel {
  --position: 1;
}

input:nth-of-type(2) {
  grid-column: 3 / 4;
  grid-row: 2 / 3;
}

input:nth-of-type(2):checked~main#carousel {
  --position: 2;
}

input:nth-of-type(3) {
  grid-column: 4 /5;
  grid-row: 2 / 3;
}

input:nth-of-type(3):checked~main#carousel {
  --position: 3;
}

input:nth-of-type(4) {
  grid-column: 5 / 6;
  grid-row: 2 / 3;
}

input:nth-of-type(4):checked~main#carousel {
  --position: 4;
}

input:nth-of-type(5) {
  grid-column: 6 / 7;
  grid-row: 2 / 3;
}

input:nth-of-type(5):checked~main#carousel {
  --position: 5;
}

.modal-content {
  width: 800px;
  background-color: #ffffff;
}



.namebox:hover {
  color: white !important;
  background-color: blue !important;
  cursor: pointer;
}

.modal-dialog {
  background-color: #E8E8E8 !important;
  color: black !important;
}

// .modal :hover{
//   background-color: #E8E8E8 !important;
//   color: black !important;
// }

.collapse {
  display: none
}

.collapse.in {
  display: block
}